import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ComponentList from "~/components/ComponentStatus/ComponentList/ComponentList";
export const _frontmatter = {
  "menuLabel": "Component Status",
  "sortOrder": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Component Status`}</h1>
    <p className="p-large">To help you stay up to date, we have created this table to show you all the components that are currently available and what's planned for the future.</p>
    <p>{`If there's a component you'd like to suggest please `}<a parentName="p" {...{
        "href": "/help-feedback"
      }}>{`reach out`}</a>{`.`}</p>
    <h2>Upcoming Components (Q4/24 - Q1/25)</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`New Component: Item`}</strong>{` - Elements that contain many different components. `}<em parentName="li">{`(Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`New Component: Tab Nav`}</strong>{` - Similar to Pill Nav, but with a different style. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Enhancement: forcedReadability Button`}</strong>{` - Forces a button to be white or black, depending on the background color. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`New Component: Status Message, aka Alert`}</strong>{` - Display any type of message for our users. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Component Variant: Form Input / SearchInput`}</strong>{` - A stylized input for searching a page or site. `}<em parentName="li">{`(iOS)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Component Variant: Form Input / SecureInput`}</strong>{` - A input with features for adding secure information. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`New Component: Scoreboard`}</strong>{` - Providing more context to games and matches, like periods, innings, quarters and tennis scores. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Component Enhancement: Pill Nav`}</strong>{` - Adding visual options like highlighting, badges, and more. `}<em parentName="li">{`(iOS and Web)`}</em></li>
      <li parentName="ul"><strong parentName="li">{`New Component: Checkbox and radio`}</strong>{` - A basic component used in forms. `}<em parentName="li">{`(iOS and Web)`}</em></li>
    </ul>
    <p>{`If there's a component you'd like to suggest please `}<a parentName="p" {...{
        "href": "/help-feedback"
      }}>{`reach out`}</a>{`.`}</p>
    <ComponentList mdxType="ComponentList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      